export const environment = {
    production: false,
    auth0:{
        domain: 'rgp-cloud-dev.us.auth0.com',
        clientId: '3M5HP5HnUhcRIZQ7P9TJta4W8qMqcC97',
        audience: 'jarcatech/rgp-cloud',
        scope:'openid offline_access'
    },
};

export const apisConfig = {
    serverUrl:"https://dev-api.jarcatech.app/rgp-pw/",
    defaultVersion:"v1",
    auth0Version:"v2"
};
