import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, map, mergeMap, Observable, of, ReplaySubject, tap, throwError} from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import {AuthService} from "@auth0/auth0-angular";
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _authService: AuthService, private jwtHelper: JwtHelperService)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        return this._authService.getAccessTokenSilently().pipe(
            map((token ) => {
                const decodedToken = this.jwtHelper.decodeToken(token);
                return (decodedToken.permissions || []).join(',');
            }),
            catchError((error) => {
                console.log(error);
                return of('');
            }),
            mergeMap(permissions => {
                return this._httpClient.get<Navigation>('api/common/navigation', {params: {permissions}}).pipe(
                    tap((navigation) => {
                        this._navigation.next(navigation);
                    }),
                    catchError( err => {
                        console.log(err)
                        return throwError(err);
                    })
                )
            })
        );
    }
}
