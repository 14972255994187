import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _authService: AuthService) {}

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(
            catchError((error) => {
                if( error instanceof Error && (
                    error.message === 'Login required' || error.message === 'Unknown or invalid refresh token.'
                )){
                    this.logout();
                }else if ( error instanceof HttpErrorResponse && error.status === 401) {
                    this.logout();
                }else{
                    return throwError(error);
                }
            })
        );
    }

    logout(){
        // Sign out
        this._authService.logout({ logoutParams: { returnTo: document.location.origin } });
        // Reload the app
        location.reload();
    }
}
