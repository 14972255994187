import { Route } from '@angular/router';
import { AuthGuard as CustomAuthGuard } from 'app/core/auth/guards/auth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import {AuthGuard} from '@auth0/auth0-angular'

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboard'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes for authenticated users
    {
        path: '',
        canActivate:[AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)},
        ]
    },

    // User routes
    {
        path: '',
        canActivate:[AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard', loadChildren: () => import('app/modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
            {path: 'permits', loadChildren: () => import('app/modules/permits/permits.module').then(m => m.PermitsModule)},
            {path: 'fishers', loadChildren: () => import('app/modules/fishers/fishers.module').then(m => m.FishersModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate:[AuthGuard, CustomAuthGuard],
        component: LayoutComponent,
        data: {
            expectedPermissions: ['app:administrator']
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'users', loadChildren: () => import('app/modules/users/users.module').then(m => m.UsersModule)},
            {path: 'fishing-groups', loadChildren: () => import('app/modules/fishing-group/fishing-group.module').then(m => m.FishingGroupModule)},
            {path: 'fishing-markets', loadChildren: () => import('app/modules/fishing-market/fishing-market.module').then(m => m.FishingMarketModule)},
            {path: 'permit-types', loadChildren: () => import('app/modules/permit-type/permit-type.module').then(m => m.PermitTypeModule)},
            {path: 'fishing-methods', loadChildren: () => import('app/modules/fishing-method/fishing-method.module').then(m => m.FishingMethodModule)},
            {path: 'fishing-types', loadChildren: () => import('app/modules/fishing-types/fishing-types.module').then(m => m.FishingTypesModule)},
            {path: 'fishing-zones', loadChildren: () => import('app/modules/fishing-zone/fishing-zone.module').then(m => m.FishingZoneModule)},
            {path: 'fishing-frequencies', loadChildren: () => import('app/modules/fishing-frequency/fishing-frequency.module').then(m => m.FishingFrequencyModule)},
            {path: 'boat-types', loadChildren: () => import('app/modules/boat-type/boat-type.module').then(m => m.BoatTypeModule)},
            {path: 'boat-construction-types', loadChildren: () => import('app/modules/boat-construction-type/boat-construction-type.module').then(m => m.BoatConstructionTypeModule)},
            {path: 'boat-powered-types', loadChildren: () => import('app/modules/boat-powered-type/boat-powered-type.module').then(m => m.BoatPoweredTypeModule)},
            {path: 'habitat-types', loadChildren: () => import('app/modules/habitat-type/habitat-type.module').then(m => m.HabitatTypeModule)},
            {path: 'identification-types', loadChildren: () => import('app/modules/identification-type/identification-type.module').then(m => m.IdentificationTypeModule)},
            {path: 'nationalities', loadChildren: () => import('app/modules/nationalities/nationalities.module').then(m => m.NationalitiesModule)},
            {path: 'states', loadChildren: () => import('app/modules/states/states.module').then(m => m.StatesModule)},
            {path: 'occupations', loadChildren: () => import('app/modules/occupations/occupations.module').then(m => m.OccupationsModule)},
            {path: 'tour-operators', loadChildren: () => import('app/modules/tour-operators/tour-operators.module').then(m => m.TourOperatorsModule)},
            {path: 'signatures', loadChildren: () => import('app/modules/signatures/signatures.module').then(m => m.SignaturesModule)},
        ]
    }
];
