import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatTableModule } from '@angular/material/table'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { apisConfig, environment } from 'environments/environment';
import { ErrorCatchingInterceptor } from './core/auth/error-catching.interceptor';

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        AuthModule.forRoot({
            domain: environment.auth0.domain,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                scope:environment.auth0.scope,
                audience:environment.auth0.audience
            },

            cacheLocation:"localstorage",
            useRefreshTokens: true,
            httpInterceptor:{
                allowedList:[
                    {
                        uri: apisConfig.serverUrl +  '*',
                        tokenOptions:{
                            authorizationParams: {
                                scope:environment.auth0.scope,
                                audience:environment.auth0.audience
                            }
                        }
                    }
                ],
            }
        }),
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true},
        JwtHelperService,
        {provide: JWT_OPTIONS, useValue: JWT_OPTIONS}
    ],

    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
