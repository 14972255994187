/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:analytics',
        link : '/dashboard'
    },
    {
        id   : 'fishers',
        title: 'Fishers',
        type : 'basic',
        icon : 'heroicons_outline:user-circle',
        link : '/fishers'
    },
    {
        id   : 'permits',
        title: 'Permits',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/permits'
    },
    {
        id   : 'user-management',
        title: 'User Management',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/users',
        meta: {
            permission: ['app:administrator']
        },
    },
    {
        id   : 'settings',
        title: 'Settings',
        type : 'group',
        icon : 'heroicons_outline:adjustments',
        link : '/settings',
        meta: {
            permission: ['app:administrator']
        },
        children: [
            {
                id   : 'fishing-groups',
                title: 'Fishing Groups',
                type : 'basic',
                link : '/fishing-groups',
            },
            {
                id   : 'fishing-markets',
                title: 'Fishing Markets',
                type : 'basic',
                link : '/fishing-markets'
            },
            {
                id   : 'permit-types',
                title: 'Permit Types',
                type : 'basic',
                link : '/permit-types'
            },
            {
                id   : 'fishing-methods',
                title: 'Fishing Methods',
                type : 'basic',
                link : '/fishing-methods'
            },
            {
                id   : 'fishing-types',
                title: 'Fishing Type',
                type : 'basic',
                link : '/fishing-types'
            },
            {
                id   : 'fishing-zones',
                title: 'Fishing Zones',
                type : 'basic',
                link : '/fishing-zones'
            },
            {
                id   : 'fishing-frequencies',
                title: 'Fishing Frequencies',
                type : 'basic',
                link : '/fishing-frequencies'
            },
            {
                id   : 'boat-types',
                title: 'Boat Types',
                type : 'basic',
                link : '/boat-types'
            },
            {
                id   : 'boat-construction-types',
                title: 'Boat Construction Types',
                type : 'basic',
                link : '/boat-construction-types'
            },
            {
                id   : 'boat-powered-types',
                title: 'Boat Powered Types',
                type : 'basic',
                link : '/boat-powered-types'
            },
            {
                id   : 'habitat-types',
                title: 'Habitat Types',
                type : 'basic',
                link : '/habitat-types'
            },
            {
                id   : 'identification-types',
                title: 'Identification Types',
                type : 'basic',
                link : '/identification-types'
            },
            {
                id   : 'nationalities',
                title: 'Nationalities',
                type : 'basic',
                link : '/nationalities'
            },
            {
                id   : 'states',
                title: 'States',
                type : 'basic',
                link : '/states'
            },
            {
                id   : 'occupations',
                title: 'Occupations',
                type : 'basic',
                link : '/occupations'
            },
            {
                id   : 'tour-operators',
                title: 'Tour Operators',
                type : 'basic',
                link : '/tour-operators'
            },
            {
                id   : 'signature',
                title: 'Signatures',
                type : 'basic',
                link : '/signatures'
            },
        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:analytics',
        link : '/dashboard'
    },
    {
        id   : 'fishers',
        title: 'Fishers',
        type : 'basic',
        icon : 'heroicons_outline:user-circle',
        link : '/fishers'
    },
    {
        id   : 'permits',
        title: 'Permits',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/permits'
    },
    {
        id   : 'user-management',
        title: 'User Management',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/user-management',
        meta: {
            permission: ['app:administrator']
        },
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:analytics',
        link : '/dashboard'
    },
    {
        id   : 'fishers',
        title: 'Fishers',
        type : 'basic',
        icon : 'heroicons_outline:user-circle',
        link : '/fishers'
    },
    {
        id   : 'permits',
        title: 'Permits',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/permits'
    },
    {
        id   : 'user-management',
        title: 'User Management',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/user-management',
        meta: {
            permission: ['app:administrator']
        },
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'dashboard',
        title: 'Dashboard',
        type : 'basic',
        icon : 'mat_outline:analytics',
        link : '/dashboard'
    },
    {
        id   : 'fishers',
        title: 'Fishers',
        type : 'basic',
        icon : 'heroicons_outline:user-circle',
        link : '/fishers'
    },
    {
        id   : 'permits',
        title: 'Permits',
        type : 'basic',
        icon : 'heroicons_outline:identification',
        link : '/permits'
    },
    {
        id   : 'user-management',
        title: 'User Management',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/user-management',
        meta: {
            permission: ['app:administrator']
        },
    }
];
