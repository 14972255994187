import { Injectable } from '@angular/core';
import { CanMatch, Route, Router, UrlSegment, UrlTree, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, map, catchError } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanMatch, CanActivate
{
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private jwtHelper: JwtHelperService
    )
    {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {return this._check(route)}

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {return this._check(route)}

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param Route|ActivatedRouteSnapshot
     * @private
     */
    private _check(route: Route|ActivatedRouteSnapshot): Observable<boolean>|boolean
    {
        return this._authService.getAccessTokenSilently().pipe(
            map((token ) => {
                const decodedToken = this.jwtHelper.decodeToken(token);
                const isAuthorized = this._validateAccess(decodedToken.permissions, route.data.expectedPermissions);
                if(isAuthorized){return true}
                this._router.navigate(['/']);
                return false;
            }),
            catchError((error) => {
                console.log('error', error);
                this._router.navigate(['/']);
                return of(false);
            })
        );
    }

    private _validateAccess(permissions: string[], expectedPermissions: string[]): boolean {
        return expectedPermissions.some(expectedPermission => permissions.includes(expectedPermission));
    }
}
